import { LayoutBudget } from "@components/Structural/Budgets/Layout/Layout";
import React, { useState } from "react";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { SEO } from "@components";
import TermDepositSimulatorStep1 from "@components/TermDepositSimulator/Step1/TermDepositSimulatorStep1";
import TermDepositSimulatorStep2 from "@components/TermDepositSimulator/Step2/TermDepositSimulatorStep2";
import { makeStyles } from "@material-ui/core";
import { useAuth } from "@hooks";

const CreditSimulatorView = () => {
  useAuth({ redirectIfNotLogged: true });

  const classes = useStyles();
  const [step, setStep] = useState(0);

  return (
    <>
      <SEO />
      <LayoutBudget
        className={classes.header}
        title="Simulador de Depósito a Plazo"
        onTurnBackClick={() =>
          step === 0 ? navigate(Route.rokipedia + "/?#tools") : setStep(0)
        }
      >
        {step === 0 && (
          <TermDepositSimulatorStep1 onContinue={() => setStep(1)} />
        )}
        {step !== 0 && (
          <TermDepositSimulatorStep2
            setStep={setStep}
            onContinue={setStep}
            step={step}
          />
        )}
      </LayoutBudget>
    </>
  );
};

export default CreditSimulatorView;
const useStyles = makeStyles((theme) => ({
  header: {
    "& >div:first-child": {
      height: "16rem",
      [theme.breakpoints.down(1200)]: {
        height: "21rem",
      },
    },
  },
}));
