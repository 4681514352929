import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    fontFamily: "Montserrat",
    padding: "4.5rem",
    color: theme.palette.text.primary,
    justifyContent: "center",
    gap: "4rem",
    minHeight: "40rem",
    [theme.breakpoints.down(1200)]: {
      position: "relative",
      top: "-19rem",
      zIndex: 9,
      minHeight: "0rem",
    },
    "& .containerSection": {
      width: "33.8rem",
    },
    "& .containerSection2": {
      width: "28.8rem",
    },
  },
  select: {
    minWidth: 280,
  },
  Bar: {
    maxWidth: 400,
    [theme.breakpoints.down(1200)]: {
      maxWidth: 280,
    },
  },
  containerInput: {
    display: "flex",
    gap: "3rem",
    [theme.breakpoints.down(1200)]: {
      display: "grid",
      position: "relative",
      zIndex: 9,
    },
    "& >div": {
      display: "grid",
    },
  },
  containerInputMobile: {
    boxShadow: "4px 4px 20px 2px rgb(0 0 0 / 20%)",
    padding: "2rem",
    width: "18rem",
    [theme.breakpoints.down(1200)]: {
      width: "23.4rem",
      borderRadius: 30,
      margin: "0 auto",
      background: "white",
    },
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "1.25rem",
  },
  selectorBank: {
    width: "21rem",
    [theme.breakpoints.down(1200)]: {
      width: "14rem",
    },
  },
  term: {
    width: "9rem",
    "& input": {
      padding: "14.5px 14px",
    },
  },
  amount: {
    width: "14.5rem",
    [theme.breakpoints.down(1200)]: {
      width: "9rem",
    },
  },
  button: {
    marginTop: "2rem",
    width: 200,
    [theme.breakpoints.down(1200)]: {
      display: "flex",
      margin: "2rem auto 0",
    },
  },
  amounts: {
    padding: "2.5rem 3rem",
    width: "20rem",
    border: "1px solid #8352fd",
    height: "21rem",
    fontWeight: 400,
    gap: "4rem",
    display: "grid",
    borderRadius: 30,
    [theme.breakpoints.down(1200)]: {
      width: "20rem",
    },
    "& .title": {
      display: "block",
      fontSize: "1.25rem",
      fontFamily: "Montserrat",
      color: theme.palette.text.primary,
      textTransform: "uppercase",
      "& span": {
        fontWeight: "bold",
      },
    },
    "& .amount": {
      color: "#8352fd",
      fontSize: "2.25rem",
      fontWeight: "bold",
      display: "block",
      textTransform: "uppercase",
      marginTop: "0.2rem",
    },
  },
  containerSecondSection: {
    display: "flex",
    gap: "2rem",
    [theme.breakpoints.down(1200)]: {
      display: "grid",
      justifyContent: "center",
    },
  },
  line: {
    width: "500px!important",
    height: "21rem!important",
    [theme.breakpoints.down(1200)]: {
      width: "20rem!important",
      height: "14rem!important",
    },
  },
}));
