import React, { useCallback, useState } from "react";
import { getDepositRates } from "@apollo";
import {
  ButtonOutlined,
  ButtonPrimary,
  NumericInput,
  Select,
} from "@components/Reusables";
import { useMediaQuery } from "@material-ui/core";
import { navigate } from "gatsby";
import { AVANCE_ICON, UPDATE_ICON } from "images/GoogleCloud";
import { useStyles } from "./TermDepositSimulatorStep2.style";
import { parseNumber } from "@utils";

interface props {
  step: number;
  onContinue: (value: number) => void;
  setStep: (v: number) => void;
}

const TermDepositSimulatorStep2 = ({ step, onContinue, setStep }: props) => {
  const classes = useStyles();
  const [isBank, setBank] = useState("");
  const [isAmount, setAmount] = useState(0);
  const [isTerm, setTerm] = useState(7);
  const [depositRatesState, setDepositRates] = useState<{
    rate: number;
    term: number;
  }>();
  const [isError, setError] = useState(0);
  const isMobile = useMediaQuery("(max-width: 1200px)");
  const depositRates = getDepositRates(isBank, isTerm, isAmount || 1);

  const calculateAmounts = useCallback(() => {
    setDepositRates({
      rate: depositRates?.termAndRate?.rate,
      term: depositRates?.termAndRate?.term,
    });
  }, [depositRates, depositRatesState]);
  if (
    depositRates?.error &&
    depositRates?.error?.toString().split("$")[1] !== isError
  ) {
    setError(depositRates?.error?.toString().split("$")[1]);
  }
  const disabled = !(isTerm && isAmount && isBank && isError < isAmount);

  return (
    <section className={classes.container}>
      <div className={isMobile && classes.containerInputMobile}>
        <div className={classes.containerInput}>
          <div>
            <span className={classes.title}>Banco</span>

            <Select
              className={classes.select}
              name={"bank"}
              label={"Banco"}
              options={[
                {
                  value: "INTERNACIONAL",
                  name: "BANCO INTERNACIONAL",
                },
                {
                  value: "BICE",
                  name: "BANCO BICE",
                },
                {
                  value: "ESTADO",
                  name: "BANCO ESTADO",
                },
                {
                  value: "CHILE",
                  name: "BANCO CHILE",
                },
                {
                  value: "SANTANDER",
                  name: "BANCO SANTANDER",
                },
                {
                  value: "FALABELLA",
                  name: "BANCO FALABELLA",
                },
                {
                  value: "ITAÚ",
                  name: "BANCO ITAÚ",
                },
                {
                  value: "SCOTIA",
                  name: "BANCO SCOTIA",
                },
                {
                  value: "BCI",
                  name: "BANCO BCI",
                },
              ]}
              value={isBank}
              disabled={step !== 1}
              onChange={(e) => {
                setBank(e.target.value as string);
                calculateAmounts();
              }}
            />
          </div>{" "}
          <div>
            <span className={classes.title}>Plazo</span>
            <Select
              disabled={step !== 1}
              options={[
                {
                  name: "7 días",
                  value: 7,
                },
                {
                  name: "30 días",
                  value: 30,
                },
                {
                  name: "60 días",
                  value: 60,
                },
              ]}
              value={isTerm}
              onChange={(e) => {
                setTerm(e.target.value as number);
              }}
            />
          </div>
          <div>
            <span className={classes.title}>Monto a depositar</span>
            <NumericInput
              name="month-amount"
              onChange={(e) => {
                setAmount(e.target.value as number);
              }}
              disabled={step !== 1}
              value={isAmount}
              className={classes.amount}
              error={isAmount <= isError}
              helperText={`El monto mínimo es $${parseNumber(+isError)}`}
            />
          </div>
        </div>
        <ButtonPrimary
          startIcon={<img src={step !== 2 ? AVANCE_ICON : UPDATE_ICON} />}
          className={classes.button}
          disabled={disabled}
          text={step !== 2 ? "Calcular" : "Actualizar"}
          onClick={() => {
            onContinue(2);
            calculateAmounts();
          }}
          noDegraded
        />
        {/*         {depositRates?.error}
         */}{" "}
      </div>
      {step === 2 && (
        <section className={classes.containerSecondSection}>
          <div className={classes.amounts}>
            <div>
              <span className="title">Interés (tasa base)</span>
              <span className="amount">{depositRatesState?.rate || 0}%</span>
            </div>
            <div>
              <span className="title">
                Monto al final <br />
                de <span>{isTerm}</span> días
              </span>
              <span className="amount">
                ${parseNumber(depositRatesState?.term || 0)}
              </span>
            </div>
          </div>
        </section>
      )}{" "}
      {step === 2 && (
        <section className={classes.containerSecondSection}>
          <ButtonPrimary
            noDegraded
            text="Volver a las herramientas"
            onClick={() => navigate("/rokipedia/?#tools")}
          />{" "}
          <ButtonOutlined
            text="Ir atrás y editar"
            onClick={() => {
              setStep(1);
            }}
          />
        </section>
      )}
    </section>
  );
};

export default TermDepositSimulatorStep2;
