import { ButtonPrimary } from "@components/Reusables";
import { useMediaQuery } from "@material-ui/core";
import {
  AVANCE_ICON,
  EYE_ICON,
  TERM_DEPOSIT_SIMULATOR_STEP1,
} from "images/GoogleCloud";
import React from "react";
import { useStyles } from "./TermDepositSimulatorStep1.style";

interface props {
  onContinue: () => void;
}

const TermDepositSimulatorStep1 = ({ onContinue }: props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1200px)");

  return (
    <section className={classes.container}>
      <div className="containerSection">
        <div className={classes.containerText}>
          Un <span>depósito a plazo (DAP)</span> es plata que se le entrega a
          una institución financiera con el objetivo de que la haga rentar o
          crecer. El monto en el que crece el depósito inicial es el{" "}
          <span>interés</span>, que es la <span>ganancia</span> por haberle
          prestado plata a la institución financiera.
        </div>
        <div className={`${classes.containerText} ${classes.spaceTop}`}>
          Existen DAP de 7, 30, 60, 180 y más días; distintas instituciones
          ofrecen diferentes plazos.
        </div>
        {!isMobile && <img src={TERM_DEPOSIT_SIMULATOR_STEP1} />}
      </div>
      <div className="containerSection2">
        <div className={classes.containerBlock}>
          <img className="image" src={EYE_ICON} />
          <span className={classes.title}>¿En qué me fijo?</span>
          <div className={classes.containerText2}>
            <span>
              El mejor depósito será el que te ofrezca el mayor interés.
            </span>{" "}
            La forma más fácil de <span>comparar</span> los depósitos es ver la{" "}
            <span>tasa base</span> a 30 días que entrega una institución, por
            ejemplo, si buscamos los depósitos a plazo para 30 días, tenemos que
            ver qué institución ofrece la tasa base a 30 días más alta, ya que
            esa es la que va a entregar el{" "}
            <span>mayor monto al final del período.</span>
          </div>
        </div>{" "}
        {isMobile && <img className="img" src={TERM_DEPOSIT_SIMULATOR_STEP1} />}
        <ButtonPrimary
          startIcon={<img src={AVANCE_ICON} />}
          className={classes.button}
          text="Continuar"
          onClick={onContinue}
          noDegraded
        />
      </div>
    </section>
  );
};

export default TermDepositSimulatorStep1;
