import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    fontFamily: "Montserrat",
    padding: "4.5rem",
    justifyContent: "center",
    gap: "4rem",
    "& .containerSection": {
      width: "33.8rem",
      [theme.breakpoints.down(1200)]: {
        position: "absolute",
        top: 100,
        width: "20rem",
        color: "white",
      },
    },
    "& .containerSection2": {
      width: "28.8rem",
      [theme.breakpoints.down(1200)]: {
        position: "relative",
        top: -50,
      },
    },
    [theme.breakpoints.down(1200)]: {
      padding: "0",
      "& .img": {
        marginTop: "2rem",
      },
    },
  },
  containerText: {
    color: theme.palette.text.secondary,
    fontWeight: 300,
    lineHeight: 1.67,
    [theme.breakpoints.down(1200)]: {
      color: "white",
      fontSize: "0.75rem",
    },
    "& span": {
      fontWeight: "bold",
    },
  },
  containerText2: {
    color: theme.palette.text.secondary,
    fontWeight: 300,
    [theme.breakpoints.down(1200)]: {
      fontSize: "0.75rem",
    },
    "& span": {
      fontWeight: "bold",
    },
  },
  spaceTop: {
    paddingTop: "1rem",
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    margin: "0 auto",
    fontSize: "1.5rem",
    display: "flex",
    justifyContent: "center",
    marginBottom: "2rem",
    [theme.breakpoints.down(1200)]: {
      fontSize: "1rem",
    },
  },
  containerBlock: {
    background: "white",
    boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
    borderRadius: 30,
    padding: "2rem",
    lineHeight: 2.5,
    "& .image": {
      display: "flex",
      justifyContent: "center",
      margin: "0 auto 1.2rem",
    },
  },
  button: {
    margin: "5rem auto",
    display: "flex",
  },
}));
